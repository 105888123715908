import { RoutePaths } from '../AppRouter';
import { getRouteConfig } from '../AppRouter/config/getRouteConfig';
export var PUBLIC_ROUTES = [
    RoutePaths.LOGIN,
    RoutePaths.SIGNUP,
    RoutePaths.PASSWORDRESET,
    RoutePaths.INVITE,
    RoutePaths.ACTIONS,
    RoutePaths.NEW_PASSWORD,
    RoutePaths.VERIFY_EMAIL,
];
/**
 * Generates a URL with an optional redirect parameter for authentication flow.
 * If the user tries to access a protected route, they will be redirected to the login page
 * with the original destination encoded in the redirect parameter. For public routes,
 * the original path is preserved.
 */
export var generateDeepLinkUrl = function (path) {
    var _a;
    var _b = window.location, search = _b.search, pathname = _b.pathname;
    var deepLinkUrl = encodeURIComponent(pathname + search);
    path = path !== null && path !== void 0 ? path : pathname;
    var url = new URLSearchParams(search);
    var hasRedirect = url.has('redirect');
    var isPublicRoute = PUBLIC_ROUTES.includes(path);
    var isValidRoute = (_a = getRouteConfig()) === null || _a === void 0 ? void 0 : _a.some(function (r) { return r.path === path; });
    var isSearchDirty = search === '?redirect=%2F';
    var isEmptyDeepLink = deepLinkUrl === '%2F';
    var suffix = !isEmptyDeepLink ? "?redirect=".concat(deepLinkUrl) : '';
    var nextRoute = isPublicRoute ? path : RoutePaths.LOGIN;
    if (isSearchDirty || !isValidRoute)
        return nextRoute;
    if (hasRedirect || isPublicRoute)
        return path + search;
    return nextRoute + suffix;
};
/**
 * Retrieves the redirect path from the URL's search parameters.
 * If a redirect parameter exists, it decodes and returns the path.
 * If no redirect parameter is found, it returns undefined.
 */
export var getRedirectPath = function () {
    var searchParams = new URLSearchParams(window.location.search);
    var redirectPath = searchParams.get('redirect');
    return redirectPath ? decodeURIComponent(redirectPath) : undefined;
};
/**
 * Constructs a URL path by combining the provided path with any redirect parameter from the URL.
 * If a redirect parameter exists in the URL, it decodes it and appends it to the given path.
 * For dashboard paths, the initial path is removed to prevent path duplication.
 */
export var assembleUrl = function (path) {
    var _a = window.location, pathname = _a.pathname, search = _a.search;
    path = path !== null && path !== void 0 ? path : pathname;
    var redirectPath = getRedirectPath();
    var routes = [RoutePaths.DASHBOARD, RoutePaths.LOGIN];
    path = routes.includes(path) ? '' : path + search;
    return redirectPath ? path + redirectPath : path;
};
