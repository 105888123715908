import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cls from './Loader.module.css';
import clx from 'classnames';
var Loader = function (_a) {
    var _b;
    var className = _a.className, _c = _a.style, style = _c === void 0 ? {} : _c, _d = _a.small, small = _d === void 0 ? false : _d;
    return (_jsx("div", { className: clx(cls.container, className), style: style, children: _jsxs("div", { className: clx((_b = {},
                _b[cls.common] = true,
                _b[cls['lds-ring']] = !small,
                _b[cls.small] = small,
                _b)), children: [_jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {})] }) }));
};
export default Loader;
