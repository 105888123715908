var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { AccountsContext } from './accounts-context';
import { useMutation, useQuery } from '@apollo/client';
import { useBannerContext } from '../BannerProvider/banner-context';
import { ServiceTypesLabels, } from 'app/interfaces/LicenseInterface';
import { useUserContext } from '../UserProvider/user-context';
import { ACCOUNTS_QUERY, COUNT_ACCOUNTS_QUERY, CREATE_ACCOUNT_MUTATION, DELETE_ACCOUNT_MUTATION, REMOVE_LICENSE_MUTATION, UPDATE_ACCOUNT_MUTATION, } from './accounts.gql';
import { ORGANIZATION_TIER_QUERY } from '../OrganizationProvider/organization-provider';
import { GET_USER_ACCOUNTS } from 'widgets/MicCheckInstallModal/MicCheckInstallModal.gql';
import { useAuthContext } from '../AuthProvider/auth-context';
export var AccountsProvider = function (_a) {
    var _b, _c;
    var children = _a.children;
    var _d = useState([]), accountsNew = _d[0], setAccountsNew = _d[1];
    var _e = useState([]), accounts = _e[0], setAccounts = _e[1];
    var _f = useState(true), loading = _f[0], setLoading = _f[1];
    var _g = useState(), error = _g[0], setError = _g[1];
    var show = useBannerContext().show;
    var isAuthorized = useAuthContext().isAuthorized;
    var user = useUserContext().user;
    var organizationId = user === null || user === void 0 ? void 0 : user.organization.id;
    var accountsQuery = useQuery(ACCOUNTS_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables: { organizationId: organizationId },
        skip: !organizationId,
    });
    var updateAccount = useMutation(UPDATE_ACCOUNT_MUTATION)[0];
    var removeLicense = useMutation(REMOVE_LICENSE_MUTATION)[0];
    var opts = {
        refetchQueries: [ORGANIZATION_TIER_QUERY, GET_USER_ACCOUNTS],
    };
    var createAccount = useMutation(CREATE_ACCOUNT_MUTATION, opts)[0];
    var deleteAccount = useMutation(DELETE_ACCOUNT_MUTATION, opts)[0];
    var count = useQuery(COUNT_ACCOUNTS_QUERY, {
        skip: !isAuthorized,
    });
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        if (!(accountsQuery === null || accountsQuery === void 0 ? void 0 : accountsQuery.loading) && ((_b = (_a = accountsQuery === null || accountsQuery === void 0 ? void 0 : accountsQuery.data) === null || _a === void 0 ? void 0 : _a.accounts) === null || _b === void 0 ? void 0 : _b.length)) {
            var data = (_d = (_c = accountsQuery === null || accountsQuery === void 0 ? void 0 : accountsQuery.data) === null || _c === void 0 ? void 0 : _c.accounts) === null || _d === void 0 ? void 0 : _d.reduce(function (acc, account) {
                var licenses = account.licenses, name = account.name, isDefault = account.isDefault;
                var item = [];
                if (!(licenses === null || licenses === void 0 ? void 0 : licenses.length)) {
                    item = [
                        {
                            accountId: account.id,
                            accountName: name,
                            licenseId: undefined,
                            licenseName: '',
                            licenseKey: '',
                            licenseType: '',
                            seats: undefined,
                            installs: undefined,
                            actions: !isDefault,
                            editable: !isDefault,
                        },
                    ];
                }
                else {
                    item = licenses.map(function (_a) {
                        var _b;
                        var id = _a.id, licenseName = _a.licenseName, licenseKey = _a.licenseKey, installed = _a.installed, account = _a.account, currentBillingCycle = _a.currentBillingCycle;
                        return ({
                            accountId: account.id,
                            accountName: name,
                            licenseId: id,
                            licenseName: licenseName,
                            licenseKey: licenseKey,
                            licenseType: ServiceTypesLabels[(_b = currentBillingCycle === null || currentBillingCycle === void 0 ? void 0 : currentBillingCycle.billingOption) === null || _b === void 0 ? void 0 : _b.serviceType],
                            seats: currentBillingCycle.numSeats,
                            installs: installed,
                            editable: !isDefault,
                            actions: !isDefault,
                        });
                    });
                }
                return __spreadArray(__spreadArray([], acc, true), item, true);
            }, []);
            var defaultAccountFirst = __spreadArray([], data, true).sort(function (a, b) {
                var aIsDefault = !a.editable;
                var bIsDefault = !b.editable;
                return aIsDefault ? -1 : bIsDefault ? 1 : 0;
            });
            setAccounts(((_e = accountsQuery.data) === null || _e === void 0 ? void 0 : _e.accounts) || []);
            setAccountsNew(defaultAccountFirst);
            setError(accountsQuery.error);
        }
        setLoading(false);
    }, [accountsQuery]);
    useEffect(function () {
        if (error)
            show(error.message);
    }, [error]);
    return (_jsx(AccountsContext.Provider, { value: {
            count: (_c = (_b = count.data) === null || _b === void 0 ? void 0 : _b.countAccounts) !== null && _c !== void 0 ? _c : 0,
            accounts: accounts,
            accountsNew: accountsNew,
            error: error,
            loading: loading,
            refetch: accountsQuery.refetch,
            createAccount: createAccount,
            updateAccount: updateAccount,
            deleteAccount: deleteAccount,
            removeLicense: removeLicense,
        }, children: children }));
};
