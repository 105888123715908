import { useAuthContext } from 'app/providers/AuthProvider/auth-context';
import { useUserContext } from 'app/providers/UserProvider/user-context';
import { DataLayerEvents } from './useDataLayer';
/**
 * Hook for getting the user data for Intercom via GTM
 *
 * @returns {Object} The user data for Intercom
 */
export var useIntercomUser = function () {
    var user = useUserContext().user;
    var isAuthorized = useAuthContext().isAuthorized;
    if (!user || !isAuthorized)
        return;
    var organization = user.organization, email = user.email, firstName = user.firstName, lastName = user.lastName;
    var name = firstName && lastName ? "".concat(firstName, " ").concat(lastName) : email;
    return {
        event: DataLayerEvents.UserLoggedIn,
        user: {
            company: organization === null || organization === void 0 ? void 0 : organization.name,
            companyId: organization === null || organization === void 0 ? void 0 : organization.id,
            id: user.id,
            email: email,
            name: name,
        },
    };
};
