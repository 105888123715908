import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CountUp from 'react-countup';
import classNames from 'classnames';
import { useAccounts } from 'app/providers/AccountsProvider/accounts-context';
import Icon from 'shared/assets/icons/sidebar-team.svg';
import cls from './styles.module.css';
export var TeamBadge = function () {
    var count = useAccounts().count;
    return (_jsxs("div", { className: classNames(cls.base, cls.team), children: [_jsx(CountUp, { duration: 5, end: count }), _jsx(Icon, {})] }));
};
