import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
import { Dashboard } from 'pages/Dashboard';
import { Profile } from 'pages/Profile';
import { LogInPage } from 'pages/LogInPage';
import { Licenses } from 'pages/Licenses';
import { SignUpPage } from 'pages/SignUpPage';
import { PasswordReset } from 'pages/PasswordReset';
import { VerifyEmailPage } from 'pages/VerifyEmailPage';
import Users from 'pages/Users/Users';
import { Invite } from 'pages/Invite';
import { Accounts } from 'pages/Accounts';
import FirebaseActions from 'pages/FirebaseActions/components/FirebaseActions';
import { NewPassword } from 'pages/NewPassword';
import { Agents } from 'pages/Agents';
import { Billing } from 'pages/Billing';
import { ProPlanPage } from 'pages/ProPlanPage';
import { LicenseRedirection } from 'pages/LicenseRedirection';
export var RoutePaths;
(function (RoutePaths) {
    RoutePaths["DASHBOARD"] = "/";
    RoutePaths["LOGIN"] = "/login";
    RoutePaths["SIGNUP"] = "/signup";
    RoutePaths["PRO_PLAN"] = "/pro-plan";
    RoutePaths["PROFILE"] = "/profile";
    RoutePaths["LICENSES"] = "/licenses";
    RoutePaths["TEAMS"] = "/teams";
    RoutePaths["AGENTS"] = "/agents";
    RoutePaths["BILLING"] = "/billing";
    RoutePaths["PASSWORDRESET"] = "/password-reset";
    RoutePaths["VERIFY_EMAIL"] = "/verify-email";
    RoutePaths["USERS"] = "/users";
    RoutePaths["INVITE"] = "/invite";
    RoutePaths["ACTIONS"] = "/actions";
    RoutePaths["LICENSE_MANDATE_REDIRECTION"] = "/license-mandate-redirection";
    RoutePaths["NEW_PASSWORD"] = "/new-password";
    RoutePaths["NO_MATCH"] = "*";
})(RoutePaths || (RoutePaths = {}));
export var getRouteConfig = function () {
    var routeConfig = initialRouteConfig;
    //
    return routeConfig;
};
export var initialRouteConfig = [
    {
        path: RoutePaths.DASHBOARD,
        element: _jsx(Dashboard, {}),
    },
    {
        path: RoutePaths.PROFILE,
        element: _jsx(Profile, {}),
    },
    {
        path: RoutePaths.USERS,
        element: _jsx(Users, {}),
    },
    {
        path: RoutePaths.LICENSES,
        element: _jsx(Licenses, {}),
    },
    {
        path: RoutePaths.TEAMS,
        element: _jsx(Accounts, {}),
    },
    {
        path: RoutePaths.AGENTS,
        element: _jsx(Agents, {}),
    },
    {
        path: RoutePaths.BILLING,
        element: _jsx(Billing, {}),
    },
    {
        path: RoutePaths.BILLING,
        element: _jsx(Billing, {}),
    },
    {
        path: RoutePaths.LOGIN,
        element: _jsx(LogInPage, {}),
        hideLayout: true,
    },
    {
        path: RoutePaths.SIGNUP,
        element: _jsx(SignUpPage, {}),
    },
    {
        path: RoutePaths.PRO_PLAN,
        element: _jsx(ProPlanPage, {}),
        hideLayout: true,
    },
    {
        path: RoutePaths.PASSWORDRESET,
        element: _jsx(PasswordReset, {}),
        hideLayout: true,
    },
    {
        path: RoutePaths.VERIFY_EMAIL,
        element: _jsx(VerifyEmailPage, {}),
        hideLayout: true,
    },
    {
        path: RoutePaths.INVITE,
        element: _jsx(Invite, {}),
    },
    {
        path: RoutePaths.ACTIONS,
        element: _jsx(FirebaseActions, {}),
    },
    {
        path: RoutePaths.LICENSE_MANDATE_REDIRECTION,
        element: _jsx(LicenseRedirection, {}),
        hideLayout: true,
    },
    {
        path: RoutePaths.NEW_PASSWORD,
        element: _jsx(NewPassword, {}),
        hideLayout: true,
    },
    {
        path: RoutePaths.NO_MATCH,
        element: _jsx(Navigate, { to: RoutePaths.DASHBOARD, replace: true }),
    },
];
