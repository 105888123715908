import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useDataLayer } from 'app/hooks/dataLayer/useDataLayer';
import { useIntercomUser } from 'app/hooks/dataLayer/userIntercomUser';
/**
 * Provider for Google Tag Manager.
 *
 * This provider is used to push events to Google Tag Manager.
 * It is used to track user interactions and events.
 *
 * @param {ReactNode} children - The child components to be rendered
 * @returns {ReactNode} The child components wrapped in the GtmProvider
 */
export var GtmProvider = function (_a) {
    var children = _a.children;
    var pushToDataLayer = useDataLayer().pushToDataLayer;
    var intercomUser = useIntercomUser();
    useEffect(function () {
        if (intercomUser)
            pushToDataLayer(intercomUser);
    }, [intercomUser]);
    return _jsx(_Fragment, { children: children });
};
