import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useState } from 'react';
import cls from './Header.module.css';
import { useHeaderContext } from 'app/providers/HeaderProvider/header-context';
// import DowntimeBanner from './components/DowntimeBanner/DowntimeBanner';
import { Link } from 'react-router-dom';
import Logo from 'shared/assets/icons/dark_logo.svg';
import { RoutePaths } from 'app/providers/AppRouter';
import { UserAvatar } from './components/UserAvatar/UserAvatar';
export var Header = memo(function (_a) {
    var setMenuShow = _a.setMenuShow;
    var _b = useHeaderContext(), actions = _b.actions, pageTitle = _b.pageTitle;
    var _c = useState(false), isMenuVisible = _c[0], setIsMenuVisible = _c[1];
    useEffect(function () {
        if (isMenuVisible) {
            document.body.classList.add('sidebar_open');
        }
        else {
            document.body.classList.remove('sidebar_open');
        }
    }, [isMenuVisible]);
    return (_jsxs("header", { className: cls.header, children: [_jsxs("div", { className: cls.mobile_header_top, children: [_jsx("div", { className: cls.logocontainer, children: _jsx(Link, { className: cls.logo, to: RoutePaths.DASHBOARD, children: _jsx(Logo, {}) }) }), _jsxs("button", { type: "button", className: cls.header_toggle, onClick: function () { return setMenuShow(true); }, children: [_jsx("span", {}), _jsx("span", {}), _jsx("span", {})] })] }), _jsxs("div", { className: cls.content, children: [_jsxs("div", { className: cls.actions, children: [pageTitle ? (_jsx("h1", { className: cls.action_title, children: pageTitle })) : null, actions !== null && actions !== void 0 ? actions : null] }), _jsx("div", { className: cls.hideOnMobile, children: _jsx(UserAvatar, {}) })] })] }));
});
Header.displayName = 'Header';
