import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CountUp from 'react-countup';
import classNames from 'classnames';
import { useUserContext } from 'app/providers/UserProvider/user-context';
import Icon from 'shared/assets/icons/sidebar-managers.svg';
import cls from './styles.module.css';
export var ManagersBadge = function () {
    var count = useUserContext().count;
    return (_jsxs("div", { className: classNames(cls.base, cls.managers), children: [_jsx(CountUp, { duration: 5, end: count }), _jsx(Icon, {})] }));
};
