var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var DataLayerEvents;
(function (DataLayerEvents) {
    DataLayerEvents["UserLoggedIn"] = "userLoggedIn";
    DataLayerEvents["UserLoggedOut"] = "userLoggedOut";
})(DataLayerEvents || (DataLayerEvents = {}));
/**
 * Push an event to GTM DataLayer V2
 * https://developers.google.com/tag-platform/tag-manager/datalayer
 */
export var useDataLayer = function () {
    var hasWindow = typeof window !== 'undefined';
    var dataLayer = hasWindow ? window.dataLayer || [] : [];
    var pushToDataLayer = function (props) {
        if (!props || !dataLayer)
            return;
        dataLayer.push(props);
    };
    var pushEventToDataLayer = function (event, data) {
        if (!event || !data || !dataLayer)
            return;
        dataLayer.push(__assign({ event: event }, data));
    };
    return {
        pushToDataLayer: pushToDataLayer,
        pushEventToDataLayer: pushEventToDataLayer,
    };
};
