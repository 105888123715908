import { useState, useEffect } from 'react';
// Based on breakpoints.css
var BREAKPOINTS = {
    MOBILE_MAX: 768,
    TABLET_MIN: 768,
    TABLET_MAX: 1024,
    DESKTOP_MIN: 1024,
};
var intialValues = {
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    height: 0,
    width: 0,
};
/**
 * Hook to detect viewport size based on breakpoints.css
 */
export var useViewport = function () {
    var _a = useState(intialValues), viewport = _a[0], setViewport = _a[1];
    useEffect(function () {
        var updateViewport = function () {
            var width = window.innerWidth;
            var height = window.innerHeight;
            setViewport({
                isMobile: width <= BREAKPOINTS.MOBILE_MAX,
                isTablet: width >= BREAKPOINTS.TABLET_MIN &&
                    width <= BREAKPOINTS.TABLET_MAX,
                isDesktop: width >= BREAKPOINTS.DESKTOP_MIN,
                width: width,
                height: height,
            });
        };
        updateViewport(); // Set initial values
        window.addEventListener('resize', updateViewport);
        return function () { return window.removeEventListener('resize', updateViewport); };
    }, []);
    return viewport;
};
