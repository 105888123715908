var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useMemo, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUserContext } from 'app/providers/UserProvider/user-context';
import { useViewport } from 'shared/hooks/useViewPort';
import { getSidebarItems } from './Sidebar.items';
import { Section } from './components/Section';
import { Badges } from './components/Badges';
import { UserAvatar } from 'widgets/Header/components/UserAvatar/UserAvatar';
import { Footer } from 'widgets/Footer/Footer';
import SideBarLogo from 'shared/assets/icons/SideLogo.svg';
import BetaTag from 'shared/components/BetaTag/BetaTag';
import Close from 'shared/assets/icons/close-cross.svg';
import cls from './Sidebar.module.css';
export var Sidebar = memo(function (_a) {
    var menuShow = _a.menuShow, setMenuShow = _a.setMenuShow;
    var isDesktop = useViewport().isDesktop;
    var user = useUserContext().user;
    var location = useLocation();
    // Hide sidebar on desktop
    useEffect(function () {
        if (isDesktop && menuShow)
            setMenuShow(false);
    }, [isDesktop, menuShow, setMenuShow]);
    var toggleMenu = function () { return setMenuShow(false); };
    // Close menu on ESC
    useEffect(function () {
        var handleEsc = function (e) {
            return e.key === 'Escape' && toggleMenu();
        };
        document.addEventListener('keydown', handleEsc);
        return function () { return document.removeEventListener('keydown', handleEsc); };
    }, []);
    var renderNav = useMemo(function () { return (_jsx("nav", { children: getSidebarItems().map(function (item) {
            var _a;
            return (_jsxs("section", { children: [_jsx(Section, { item: item }), (_a = item.children) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
                        var label = _a.label, href = _a.href, badges = _a.badges;
                        var active = location.pathname === href;
                        var props = {
                            'data-active': active,
                            onClick: toggleMenu,
                        };
                        return (_jsxs(Link, __assign({ to: href }, props, { children: [_jsx("span", { children: label }), _jsx(Badges, { badges: badges })] }), label));
                    })] }, item.label));
        }) })); }, [location.pathname, toggleMenu]);
    var shared = {
        'data-is-loading': !user,
        'data-is-mobile-open': menuShow,
        'data-is-menu-open': menuShow,
    };
    return (_jsxs("div", __assign({ className: cls.wrapper }, shared, { children: [_jsx("aside", __assign({ className: cls.sidebar }, shared, { children: user && (_jsxs(_Fragment, { children: [_jsxs("div", { className: cls.header, children: [_jsxs("div", { children: [_jsx(SideBarLogo, {}), _jsx(BetaTag, {})] }), _jsx("button", { onClick: toggleMenu, children: _jsx(Close, {}) })] }), _jsxs("div", { className: cls.content, children: [_jsx("div", { className: cls.profile, children: _jsx(UserAvatar, {}) }), renderNav, _jsx(Footer, {})] })] })) })), _jsx("div", { className: cls.overlay, onClick: toggleMenu })] })));
});
Sidebar.displayName = 'Sidebar';
