var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import clx from 'classnames';
import { Controller, } from 'react-hook-form';
import ViewPassword from 'shared/assets/icons/password-view.svg';
import ViewPasswordOff from 'shared/assets/icons/password-view-off.svg';
import cls from './TextInput.module.css';
var TextInput = function (_a) {
    var label = _a.label, _b = _a.placeholder, placeholder = _b === void 0 ? label : _b, showViewPasswordIcon = _a.showViewPasswordIcon, labelClassName = _a.labelClassName, type = _a.type, error = _a.error, className = _a.className, containerClassName = _a.containerClassName, control = _a.control, inputProps = __rest(_a, ["label", "placeholder", "showViewPasswordIcon", "labelClassName", "type", "error", "className", "containerClassName", "control"]);
    var _c = useState(false), viewPassword = _c[0], setViewPassword = _c[1];
    var onPasswordViewClick = function () {
        setViewPassword(!viewPassword);
    };
    var inputType = useMemo(function () { return (viewPassword ? 'text' : type); }, [viewPassword, type]);
    var renderWithControl = (_jsx(Controller, __assign({ control: control, name: inputProps.name }, (inputProps.rules ? { rules: inputProps.rules } : {}), { render: function (_a) {
            var field = _a.field, rest = __rest(_a, ["field"]);
            var error = rest.formState.errors[inputProps.name];
            return renderField(field, error);
        } })));
    var renderField = function (field, fieldError) {
        if (fieldError === void 0) { fieldError = {}; }
        var errorObj = fieldError;
        var hasError = error || (errorObj === null || errorObj === void 0 ? void 0 : errorObj.message);
        var fieldProps = __assign(__assign({ placeholder: placeholder, className: clx([cls.input, className, hasError && cls.error]), type: inputType }, inputProps), field);
        var fieldTestId = inputProps['data-testid'];
        return (_jsxs("div", { className: clx([cls.container, containerClassName]), children: [label ? (_jsx("div", { className: clx([
                        'text_label',
                        cls.label,
                        labelClassName,
                    ]), children: label })) : null, type === 'discount' ? (_jsx(NumericFormat, { suffix: "%", decimalScale: 2, allowNegative: false, value: field === null || field === void 0 ? void 0 : field.value, className: fieldProps.className, placeholder: fieldProps.placeholder, onValueChange: function (_a) {
                        var floatValue = _a.floatValue;
                        return field === null || field === void 0 ? void 0 : field.onChange(floatValue !== null && floatValue !== void 0 ? floatValue : 0);
                    }, onClick: function (e) {
                        return (fieldProps === null || fieldProps === void 0 ? void 0 : fieldProps.onClick) ? fieldProps === null || fieldProps === void 0 ? void 0 : fieldProps.onClick(e) : null;
                    } })) : inputType === 'textarea' ? (_jsx("textarea", __assign({}, (field || {}), { placeholder: placeholder, className: clx([
                        cls.input,
                        className,
                        hasError && cls.error,
                        cls.textarea,
                    ]), onInput: function (e) {
                        var target = e.target;
                        target.style.height = '68px';
                        target.style.height = "".concat(target.scrollHeight, "px");
                    } }))) : (_jsx("input", __assign({}, fieldProps))), showViewPasswordIcon ? (viewPassword ? (_jsx(ViewPassword, { className: cls.viewPassword, onClick: onPasswordViewClick, viewBox: "0 0 24 24", width: "30", height: "30" })) : (_jsx(ViewPasswordOff, { className: cls.viewPassword, onClick: onPasswordViewClick, viewBox: "0 0 24 24", width: "30", height: "30" }))) : null, hasError ? (_jsx("div", { id: "error-message", className: cls.errorMessage, "data-testid": "".concat(fieldTestId, "-error"), children: error || (errorObj === null || errorObj === void 0 ? void 0 : errorObj.message) })) : null] }));
    };
    return control ? renderWithControl : renderField();
};
export default TextInput;
