var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { UserContext } from './user-context';
import { gql, useQuery } from '@apollo/client';
import { useAuthContext } from '../AuthProvider/auth-context';
import { useBannerContext } from '../BannerProvider/banner-context';
var GET_CURRENT_USER_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query GetCurrentUser {\n        currentUser {\n            id\n            firebaseUid\n            firstName\n            lastName\n            email\n            invoices\n            role\n            organization {\n                id\n                name\n                tier {\n                    id\n                    name\n                }\n                licenses {\n                    id\n                    licenseName\n                    account {\n                        id\n                        isDefault\n                    }\n                }\n                users {\n                    id\n                    firstName\n                    lastName\n                    active\n                }\n            }\n        }\n    }\n"], ["\n    query GetCurrentUser {\n        currentUser {\n            id\n            firebaseUid\n            firstName\n            lastName\n            email\n            invoices\n            role\n            organization {\n                id\n                name\n                tier {\n                    id\n                    name\n                }\n                licenses {\n                    id\n                    licenseName\n                    account {\n                        id\n                        isDefault\n                    }\n                }\n                users {\n                    id\n                    firstName\n                    lastName\n                    active\n                }\n            }\n        }\n    }\n"])));
var GET_COUNT_MANAGERS_QUERY = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query CountManagers {\n        countManagers\n    }\n"], ["\n    query CountManagers {\n        countManagers\n    }\n"])));
export var UserProvider = function (_a) {
    var _b, _c;
    var children = _a.children;
    var _d = useState(null), user = _d[0], setUser = _d[1];
    var isAuthorized = useAuthContext().isAuthorized;
    var banner = useBannerContext();
    var logout = useAuthContext().logout;
    var _e = useQuery(GET_CURRENT_USER_QUERY, { skip: !isAuthorized }), data = _e.data, error = _e.error, refetch = _e.refetch;
    var count = useQuery(GET_COUNT_MANAGERS_QUERY, {
        skip: !isAuthorized,
    });
    useEffect(function () {
        if (error) {
            banner.show('Error fetching user data');
            logout();
        }
        else {
            if (!(data === null || data === void 0 ? void 0 : data.currentUser))
                return;
            setUser(__assign(__assign({}, data === null || data === void 0 ? void 0 : data.currentUser), { permissions: [] }));
        }
    }, [data, error]);
    // TODO: Improve this when more permissions are added
    if (user && (user === null || user === void 0 ? void 0 : user.invoices))
        user.permissions.push('invoices');
    var value = {
        count: (_c = (_b = count.data) === null || _b === void 0 ? void 0 : _b.countManagers) !== null && _c !== void 0 ? _c : 0,
        user: user,
        setUser: setUser,
        refetch: refetch,
    };
    return (_jsx(UserContext.Provider, { value: value, children: children }));
};
var templateObject_1, templateObject_2;
