import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from 'app/providers/AuthProvider/auth-context';
import LogoutActive from 'shared/assets/icons/sidebar-logout-active.svg';
import Logout from 'shared/assets/icons/sidebar-logout.svg';
import cls from './Footer.module.css';
export var Footer = memo(function (_a) {
    var size = _a.size, style = _a.style;
    var _b = useState(false), isHovering = _b[0], setIsHovering = _b[1];
    var logout = useAuthContext().logout;
    return (_jsxs("footer", { className: cls.footer, children: [_jsxs("button", { className: cls.logout, onMouseEnter: function () { return setIsHovering(true); }, onMouseLeave: function () { return setIsHovering(false); }, onClick: function () { return logout(); }, children: [isHovering ? _jsx(LogoutActive, {}) : _jsx(Logout, {}), _jsx("p", { children: "Log Out" })] }), _jsxs("div", { className: cls.links, children: [_jsx(Link, { to: "https://tomato.ai/help-center", target: "_blank", children: "Visit the Help Center" }), _jsxs("span", { children: ["\u00A9 Copyrights 2022-", new Date().getFullYear(), ". Tomato.ai"] })] })] }));
});
Footer.displayName = 'Footer';
