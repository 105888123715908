var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
export var LIST_AGENT_SESSIONS = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query ListAgentSessions($agentId: String!) {\n        listAgentSessions(agentId: $agentId) {\n            label\n            value\n        }\n    }\n"], ["\n    query ListAgentSessions($agentId: String!) {\n        listAgentSessions(agentId: $agentId) {\n            label\n            value\n        }\n    }\n"])));
export var LIST_AGENTS_QUERY = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query ListAgents($status: AgentStatus, $account: String, $q: String) {\n        listAgents(status: $status, account: $account, q: $q) {\n            id\n            firstName\n            lastName\n            email\n            createdAt\n            hasMicCheck\n            account {\n                id\n                name\n                isDefault\n                organizationId\n                slug\n                createdAt\n                updatedAt\n            }\n        }\n    }\n"], ["\n    query ListAgents($status: AgentStatus, $account: String, $q: String) {\n        listAgents(status: $status, account: $account, q: $q) {\n            id\n            firstName\n            lastName\n            email\n            createdAt\n            hasMicCheck\n            account {\n                id\n                name\n                isDefault\n                organizationId\n                slug\n                createdAt\n                updatedAt\n            }\n        }\n    }\n"])));
export var GET_AGENT_RESULT = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    query ListResult($id: String!, $date: String!) {\n        listResult(id: $id, date: $date) {\n            firstName\n            lastName\n            email\n            account\n            slug\n            recordings {\n                recordedAt\n                recordings {\n                    voice\n                    displayName\n                    description\n                    recording\n                }\n            }\n        }\n    }\n"], ["\n    query ListResult($id: String!, $date: String!) {\n        listResult(id: $id, date: $date) {\n            firstName\n            lastName\n            email\n            account\n            slug\n            recordings {\n                recordedAt\n                recordings {\n                    voice\n                    displayName\n                    description\n                    recording\n                }\n            }\n        }\n    }\n"])));
export var INVITE_TO_INSTALL_MUTATION = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation InviteToInstall($inviteToInstallInput: InviteToInstallInput!) {\n        inviteToInstall(inviteToInstallInput: $inviteToInstallInput)\n    }\n"], ["\n    mutation InviteToInstall($inviteToInstallInput: InviteToInstallInput!) {\n        inviteToInstall(inviteToInstallInput: $inviteToInstallInput)\n    }\n"])));
export var COUNT_AGENTS_QUERY = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    query CountAgents {\n        countAgents\n    }\n"], ["\n    query CountAgents {\n        countAgents\n    }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
