var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Dropdown, Space, Typography } from 'antd';
import { Avatar } from 'antd';
import { UserRoles, UserRolesLabels } from 'app/interfaces/User';
import { DownOutlined } from '@ant-design/icons';
import Profile from 'shared/assets/icons/profile.svg';
import cls from './UserAvatar.module.css';
import { Text, TextVariants } from 'shared/components';
import { useUserContext } from 'app/providers/UserProvider/user-context';
import { useEffect, useState } from 'react';
import { useAuthContext } from 'app/providers/AuthProvider/auth-context';
import { useBannerContext } from 'app/providers/BannerProvider/banner-context';
import { useNavigate } from 'react-router-dom';
import { PROXY_LOGIN_MUTATION } from './UserAvatar.gql';
import { useMutation } from '@apollo/client';
import { USERS_QUERY } from './UserAvatar.gql';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
var renderName = function (user, isAdmin, isList) {
    var _a, _b;
    if (isAdmin === void 0) { isAdmin = false; }
    if (isList === void 0) { isList = false; }
    var adminBadge = isAdmin && _jsx("span", { className: cls.adminBadge, children: "A" });
    var hasName = (user === null || user === void 0 ? void 0 : user.firstName) || (user === null || user === void 0 ? void 0 : user.lastName);
    return (_jsxs("div", { className: cls.nameWrapper, children: [isList && '- ', adminBadge, hasName
                ? "".concat((_a = user === null || user === void 0 ? void 0 : user.firstName) !== null && _a !== void 0 ? _a : '', " ").concat((_b = user === null || user === void 0 ? void 0 : user.lastName) !== null && _b !== void 0 ? _b : '').trim()
                : user === null || user === void 0 ? void 0 : user.email] }));
};
var renderAvatar = function (user) {
    var hasFirstName = user === null || user === void 0 ? void 0 : user.firstName;
    var hasLastName = user === null || user === void 0 ? void 0 : user.lastName;
    var hasInitials = hasFirstName || hasLastName;
    return hasInitials ? ("".concat(hasFirstName === null || hasFirstName === void 0 ? void 0 : hasFirstName.charAt(0).toUpperCase()).concat(hasLastName === null || hasLastName === void 0 ? void 0 : hasLastName.charAt(0).toUpperCase()).trim()) : (_jsx(Profile, {}));
};
export var UserAvatar = function () {
    var user = useUserContext().user;
    var proxyLogin = useAuthContext().proxyLogin;
    var navigate = useNavigate();
    var show = useBannerContext().show;
    var _a = useState([
        { label: 'Loading...', key: '1', disabled: true },
    ]), proxyLoginOptions = _a[0], setProxyLoginOptions = _a[1];
    var usersQuery = useQuery(USERS_QUERY, {
        skip: (user === null || user === void 0 ? void 0 : user.role) !== UserRoles.SUPER_ADMIN,
    });
    var proxyLoginMutation = useMutation(PROXY_LOGIN_MUTATION)[0];
    useEffect(function () {
        if (!usersQuery.loading) {
            if (usersQuery.error) {
                show('Error fetching users');
            }
            else if (usersQuery.data) {
                var group_1 = _.groupBy(usersQuery.data.activeUsers, 'organization.name');
                var groups = Object.keys(group_1).map(function (key) { return ({
                    key: key,
                    type: 'group',
                    label: key,
                    children: group_1[key].map(function (user) {
                        var isAdmin = [UserRoles.ADMIN].includes(user === null || user === void 0 ? void 0 : user.role);
                        return {
                            key: user.firebaseUid,
                            label: renderName(user, isAdmin, true),
                        };
                    }),
                }); });
                setProxyLoginOptions(__spreadArray([], groups, true));
            }
        }
    }, [usersQuery]);
    var onProxyLoginClick = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
        var _c, data, errors, error;
        var key = _b.key;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, proxyLoginMutation({
                        variables: { firebaseUid: key },
                    })];
                case 1:
                    _c = _d.sent(), data = _c.data, errors = _c.errors;
                    if (!(data === null || data === void 0 ? void 0 : data.proxyLogin)) return [3 /*break*/, 3];
                    return [4 /*yield*/, proxyLogin(data === null || data === void 0 ? void 0 : data.proxyLogin)];
                case 2:
                    error = _d.sent();
                    if (error) {
                        show(error);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    show(JSON.stringify(errors));
                    _d.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: cls.wrapper, children: [_jsxs("div", { className: cls.info, children: [_jsx(Text, { tag: "p", classNamesProps: cls.userTitle, variant: TextVariants.PARAGRAPH_TITLE, children: user && renderName(user) }), (user === null || user === void 0 ? void 0 : user.role) === UserRoles.SUPER_ADMIN ? (_jsx(_Fragment, { children: _jsx(Dropdown, { menu: {
                                items: proxyLoginOptions,
                                onClick: onProxyLoginClick,
                                selectable: true,
                            }, className: cls.proxy_login, rootClassName: cls.proxy_login_dropdown, children: _jsx(Typography.Link, { children: _jsxs(Space, { size: 5, children: ["All Users", _jsx(DownOutlined, { size: 5 })] }) }) }) })) : (_jsx(Typography.Link, { children: (user === null || user === void 0 ? void 0 : user.role) ? UserRolesLabels[user === null || user === void 0 ? void 0 : user.role] : '' }))] }), _jsx(Avatar, { size: 46, className: cls.avatar, onClick: function () { return navigate('/profile'); }, children: user ? renderAvatar(user) : '...' })] }));
};
