var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { createApolloClient } from 'apollo';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from 'app/providers/AppRouter';
import { ErrorBoundary } from 'app/providers/ErrorBoundary';
import { ContextProvider } from 'app/providers/ContextProvider';
import { AuthProvider } from './providers/AuthProvider/auth-provider';
import { UserProvider } from './providers/UserProvider/user-provider';
import { OrganizationProvider } from './providers/OrganizationProvider/organization-provider';
import { HeaderProvider } from './providers/HeaderProvider/header-provider';
import { BannerProvider } from './providers/BannerProvider/banner-provider';
import { AccountsProvider } from './providers/AccountsProvider/accounts.provider';
import { MicCheckProvider } from './providers/MicCheckProvider/mic-check.provider';
import { GtmProvider } from './providers/GtmProvider/gtm-provider';
import Layout from 'sections/Layout/Layout';
import './styles/index.css';
var App = function () {
    var _a = useState(createApolloClient()), client = _a[0], setClient = _a[1];
    var updateApolloClient = function (token) {
        setClient(createApolloClient(token));
    };
    // Compose providers in a more maintainable way
    var composeProviders = function (providers, children) {
        return providers.reduceRight(function (acc, _a) {
            var Provider = _a.Provider, _b = _a.props, props = _b === void 0 ? {} : _b;
            return (_jsx(Provider, __assign({}, props, { children: acc })));
        }, children);
    };
    // Providers order are important
    var appProviders = [
        { Provider: BrowserRouter },
        { Provider: ErrorBoundary },
        { Provider: ApolloProvider, props: { client: client } },
        { Provider: ContextProvider },
        { Provider: BannerProvider },
        { Provider: AuthProvider, props: { updateApolloClient: updateApolloClient } },
        { Provider: UserProvider },
        { Provider: OrganizationProvider },
        { Provider: AccountsProvider },
        { Provider: HeaderProvider },
        { Provider: MicCheckProvider },
        { Provider: GtmProvider },
        { Provider: Layout },
    ];
    return composeProviders(appProviders, _jsx(AppRouter, {}));
};
export default App;
