var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { MicCheckContext } from './mic-check-context';
import useQsParams from 'shared/hooks/useQsParams';
import { BannerTypes, useBannerContext, } from '../BannerProvider/banner-context';
import { GET_AGENT_RESULT, LIST_AGENTS_QUERY, INVITE_TO_INSTALL_MUTATION, LIST_AGENT_SESSIONS, COUNT_AGENTS_QUERY, } from './mic-check.gql';
import { useAuthContext } from '../AuthProvider/auth-context';
import { useLocation } from 'react-router-dom';
var sharedInitials = {
    loading: false,
    error: undefined,
};
export var MicCheckProvider = function (_a) {
    var _b, _c, _d;
    var children = _a.children;
    var show = useBannerContext().show;
    var isAuthorized = useAuthContext().isAuthorized;
    var searchParams = useQsParams().searchParams;
    // Check if the current page is the agents page
    var isOnPage = useLocation().pathname.includes('agents');
    var sessions = useQuery(LIST_AGENT_SESSIONS, {
        fetchPolicy: 'network-only',
        skip: !isOnPage || !searchParams.get('result') || !isAuthorized,
        variables: { agentId: searchParams.get('result') },
        onError: function (error) {
            show('Error fetching mic check sessions. Please try again.');
            Sentry.captureException(error);
        },
    });
    var list = useQuery(LIST_AGENTS_QUERY, {
        skip: !isOnPage || !isAuthorized,
        variables: {
            status: searchParams.get('status'),
            account: searchParams.get('account'),
            q: searchParams.get('q'),
        },
        pollInterval: isOnPage ? 10000 : 0,
        onError: function (error) {
            show('Error fetching mic check results. Please try again.');
            Sentry.captureException(error);
        },
    });
    var item = useQuery(GET_AGENT_RESULT, {
        fetchPolicy: 'network-only',
        skip: !isOnPage ||
            !searchParams.get('result') ||
            !searchParams.get('session') ||
            !isAuthorized,
        variables: {
            id: searchParams.get('result'),
            date: searchParams.get('session'),
        },
        onError: function (error) {
            console.info('ERROR', error);
            show('Error fetching mic check results. Please try again.');
            Sentry.captureException(error);
        },
    });
    var itemParse = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var result = (_b = (_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.listResult) === null || _b === void 0 ? void 0 : _b.recordings[0];
        return {
            account: ((_d = (_c = item === null || item === void 0 ? void 0 : item.data) === null || _c === void 0 ? void 0 : _c.listResult) === null || _d === void 0 ? void 0 : _d.account) || '',
            slug: ((_f = (_e = item === null || item === void 0 ? void 0 : item.data) === null || _e === void 0 ? void 0 : _e.listResult) === null || _f === void 0 ? void 0 : _f.slug) || '',
            email: ((_h = (_g = item === null || item === void 0 ? void 0 : item.data) === null || _g === void 0 ? void 0 : _g.listResult) === null || _h === void 0 ? void 0 : _h.email) || '',
            firstName: ((_k = (_j = item === null || item === void 0 ? void 0 : item.data) === null || _j === void 0 ? void 0 : _j.listResult) === null || _k === void 0 ? void 0 : _k.firstName) || '',
            recordedAt: (result === null || result === void 0 ? void 0 : result.recordedAt) || '',
            original: (result === null || result === void 0 ? void 0 : result.recordings[0]) || {},
            denoiser: (result === null || result === void 0 ? void 0 : result.recordings[result.recordings.length - 1]) || {},
            accent: (result === null || result === void 0 ? void 0 : result.recordings.slice(1, -1)) || [],
        };
    };
    var _e = useMutation(INVITE_TO_INSTALL_MUTATION, {
        onError: function (error) {
            show('Error sending invite. Please try again.');
            Sentry.captureException(error);
            throw error;
        },
        onCompleted: function () {
            show('Invite sent successfully', BannerTypes.SUCCESS);
        },
    }), submitInvite = _e[0], submitInviteProps = _e[1];
    var countAgents = useQuery(COUNT_AGENTS_QUERY, { skip: !isAuthorized });
    return (_jsx(MicCheckContext.Provider, { value: {
            resultId: searchParams.get('result'),
            list: __assign(__assign({}, sharedInitials), { loading: list.loading, error: list.error, data: ((_b = list === null || list === void 0 ? void 0 : list.data) === null || _b === void 0 ? void 0 : _b.listAgents) || [] }),
            sessions: __assign(__assign({}, sharedInitials), { loading: sessions.loading, error: sessions.error, data: ((_c = sessions === null || sessions === void 0 ? void 0 : sessions.data) === null || _c === void 0 ? void 0 : _c.listAgentSessions) || [] }),
            item: __assign(__assign({}, sharedInitials), { loading: item.loading, error: item.error, data: itemParse() }),
            submitInvite: __assign(__assign({}, sharedInitials), { loading: submitInviteProps.loading, error: submitInviteProps.error, submit: function (inviteToInstallInput) { return __awaiter(void 0, void 0, void 0, function () {
                    var variables;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                variables = { inviteToInstallInput: inviteToInstallInput };
                                return [4 /*yield*/, submitInvite({ variables: variables })];
                            case 1: return [2 /*return*/, _a.sent()];
                        }
                    });
                }); } }),
            countAgents: ((_d = countAgents === null || countAgents === void 0 ? void 0 : countAgents.data) === null || _d === void 0 ? void 0 : _d.countAgents) || 0,
        }, children: children }));
};
