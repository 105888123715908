import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cls from './Menu.module.css';
import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { SingleMenuItem } from './components/SingleMenuItem/SingleMenuItem';
import { Context } from 'app/providers/ContextProvider';
// const MENU_ITEM_HEIGHT = 65;
export var Menu = function (props) {
    var _a;
    var isMobile = useContext(Context).isMobile;
    var isEnterprise = props.isEnterprise, items = props.items, onItemChange = props.onItemChange, withIcon = props.withIcon, currentMenuItemIndex = props.currentMenuItemIndex;
    var _b = useState(65), itemHeight = _b[0], setItemHeight = _b[1];
    var _c = useState('translateY(0px)'), selectOffset = _c[0], setSelectOffset = _c[1];
    useEffect(function () {
        setItemHeight(isMobile ? 58 : 65);
    }, [isMobile]);
    useEffect(function () {
        setSelectOffset("translateY(".concat(itemHeight * currentMenuItemIndex, "px)"));
    }, [currentMenuItemIndex, itemHeight]);
    return (_jsxs("ul", { className: classNames(cls.menuItems, (_a = {}, _a[cls.dark] = isEnterprise, _a)), children: [items.map(function (item, index) { return (_jsx(SingleMenuItem, { active: currentMenuItemIndex === index, item: item, index: index, onItemChange: onItemChange, isEnterprise: isEnterprise, withIcon: withIcon }, item.title)); }), _jsx("div", { className: cls.select, style: { transform: selectOffset } })] }));
};
