var _a, _b;
/**
 * This is a mapping of user roles.
 * It is used to determine the role of a user.
 */
var UserRoles;
(function (UserRoles) {
    UserRoles["SUPER_ADMIN"] = "superAdmin";
    UserRoles["ADMIN"] = "admin";
    UserRoles["USER"] = "user";
})(UserRoles || (UserRoles = {}));
/**
 * This is a mapping of user roles to their access level.
 * It is used to determine if a user has access to a given item.
 * The lower the number, the higher the access level.
 */
var UserAccessLevel = (_a = {},
    _a[UserRoles.SUPER_ADMIN] = 0,
    _a[UserRoles.ADMIN] = 1,
    _a[UserRoles.USER] = 2,
    _a);
/**
 * This is a mapping of user roles to their labels.
 * It is used to determine the label of a user.
 */
var UserRolesLabels = (_b = {},
    _b[UserRoles.SUPER_ADMIN] = 'Super Admin',
    _b[UserRoles.ADMIN] = 'Admin',
    _b[UserRoles.USER] = 'Viewer',
    _b);
export { UserRoles, UserRolesLabels, UserAccessLevel };
