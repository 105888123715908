var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { RoutePaths } from 'app/providers/AppRouter';
import { AgentsMicCheckBadge } from './components/badges/AgentsMicCheck';
import { ManagersBadge } from './components/badges/Managers';
import { TeamBadge } from './components/badges/Team';
import { useUserContext } from 'app/providers/UserProvider/user-context';
import { UserAccessLevel, UserRoles } from 'app/interfaces/User';
import BillingIcon from 'shared/assets/icons/sidebar-billing.svg';
import UsersIcon from 'shared/assets/icons/sidebar-users.svg';
import HomeIcon from 'shared/assets/icons/sidebar-home.svg';
var sidebarItems = [
    {
        icon: _jsx(HomeIcon, {}),
        label: 'Home',
        children: [
            {
                label: 'Dashboard',
                href: RoutePaths.DASHBOARD,
            },
            {
                label: 'Licenses',
                href: RoutePaths.LICENSES,
                role: UserRoles.ADMIN,
            },
            // {
            //     label: 'Seats',
            //     href: '#',
            //     role: UserRoles.ADMIN,
            // },
        ],
    },
    {
        icon: _jsx(UsersIcon, {}),
        label: 'Users',
        children: [
            {
                label: 'Managers',
                href: RoutePaths.USERS,
                badges: [_jsx(ManagersBadge, {})],
                role: UserRoles.ADMIN,
            },
            {
                label: 'Agents',
                href: RoutePaths.AGENTS,
                badges: [_jsx(AgentsMicCheckBadge, {})],
                role: UserRoles.ADMIN,
            },
            {
                label: 'Teams',
                href: RoutePaths.TEAMS,
                badges: [_jsx(TeamBadge, {})],
            },
        ],
    },
    {
        icon: _jsx(BillingIcon, {}),
        label: 'Billing',
        href: RoutePaths.BILLING,
        role: UserRoles.ADMIN,
        permission: ['invoices'],
    },
];
/**
 * Sidebar menu items based on the current user's role and permissions.
 * - Checks if user has required role access level
 * - Verifies user has necessary permissions when specified
 * @returns Array of sidebar items the user can access
 */
export var getSidebarItems = function () {
    var user = useUserContext().user;
    if (!user)
        return [];
    var userLevel = UserAccessLevel[user === null || user === void 0 ? void 0 : user.role];
    var _hasRole = function (role) { return UserAccessLevel[role] >= userLevel; };
    var _hasPermission = function (permissions) {
        return permissions.some(function (p) { return user.permissions.includes(p); });
    };
    return (sidebarItems
        // Filter children based on user role
        .map(function (item) {
        var currItem = __assign({}, item);
        if (!currItem.children)
            return currItem;
        currItem.children = currItem.children.filter(function (child) { return !child.role || _hasRole(child.role); });
        return currItem;
    })
        // Filter parent items based on role
        .filter(function (_a) {
        var role = _a.role, permission = _a.permission, children = _a.children;
        if (!role)
            return true;
        var hasRole = _hasRole(role);
        var hasPermission = !permission || _hasPermission(permission);
        // Both permission and role requirements must be met
        if (!hasRole || !hasPermission)
            return false;
        // Remove parent item if they have no children
        return !children || children.length;
    }));
};
