import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuthContext } from 'app/providers/AuthProvider/auth-context';
import { Header } from 'widgets';
import { Sidebar } from 'widgets/Sidebar';
import cls from './Layout.module.css';
import DowntimeScreen from 'sections/DowntimeScreen/DowntimeScreen';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getRouteConfig, } from 'app/providers/AppRouter/config/getRouteConfig';
import { useUserContext } from 'app/providers/UserProvider/user-context';
import { PUBLIC_ROUTES } from 'app/providers/AuthProvider/auth-provider';
import Loader from 'shared/components/Loader/Loader';
var AppWrapper = function (_a) {
    var children = _a.children;
    return (_jsx("div", { className: "app light", id: "app-layout", children: children }));
};
var Layout = function (_a) {
    var _b;
    var children = _a.children;
    var user = useUserContext().user;
    var isAuthorized = useAuthContext().isAuthorized;
    var location = useLocation();
    var _c = useState(false), isMenuVisible = _c[0], setIsMenuVisible = _c[1];
    var route = location.pathname;
    var isPublicRoute = PUBLIC_ROUTES.includes(route);
    var hideLayout = (_b = getRouteConfig().find(function (_a) {
        var path = _a.path;
        return path === route;
    })) === null || _b === void 0 ? void 0 : _b.hideLayout;
    // TODO: Dynamically change these variables
    var isDowntime = false;
    var backOnlineDate = new Date();
    if (isDowntime) {
        return (_jsx("div", { className: "app light", children: _jsx(DowntimeScreen, { backOnlineDate: backOnlineDate }) }));
    }
    useEffect(function () {
        if (isMenuVisible) {
            document.body.classList.add('sidebar_open');
        }
        else {
            document.body.classList.remove('sidebar_open');
        }
    }, [isMenuVisible]);
    var withLayout = (_jsxs(_Fragment, { children: [_jsx(Sidebar, { menuShow: isMenuVisible, setMenuShow: setIsMenuVisible }), user ? (_jsxs(_Fragment, { children: [_jsx(Header, { setMenuShow: setIsMenuVisible }), _jsx("div", { className: cls.container, children: _jsx("div", { className: cls.content, children: children }) })] })) : (_jsx(Loader, {}))] }));
    // Render full layout if user is authorized, otherwise render loader
    var renderAuthScreen = isAuthorized ? withLayout : _jsx(Loader, {});
    var renderChildren = !isPublicRoute && !hideLayout;
    return (_jsx(AppWrapper, { children: renderChildren ? renderAuthScreen : children }));
};
export default Layout;
