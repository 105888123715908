import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CountUp from 'react-countup';
import classNames from 'classnames';
import { useMicCheck } from 'app/providers/MicCheckProvider/mic-check-context';
import Icon from 'shared/assets/icons/sidebar-mic.svg';
import cls from './styles.module.css';
export var AgentsMicCheckBadge = function () {
    var countAgents = useMicCheck().countAgents;
    return (_jsxs("div", { className: classNames(cls.base, cls.agentsMicCheck), children: [_jsx(CountUp, { duration: 5, end: countAgents }), _jsx(Icon, {})] }));
};
