var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
export var ACCOUNTS_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query Accounts($organizationId: ID!) {\n        accounts(organizationId: $organizationId) {\n            id\n            name\n            isDefault\n            users {\n                id\n            }\n            licenses {\n                id\n                licenseName\n                licenseKey\n                installed\n                currentBillingCycle {\n                    numSeats\n                    billingOption {\n                        serviceType\n                    }\n                    active\n                }\n                account {\n                    id\n                }\n            }\n        }\n    }\n"], ["\n    query Accounts($organizationId: ID!) {\n        accounts(organizationId: $organizationId) {\n            id\n            name\n            isDefault\n            users {\n                id\n            }\n            licenses {\n                id\n                licenseName\n                licenseKey\n                installed\n                currentBillingCycle {\n                    numSeats\n                    billingOption {\n                        serviceType\n                    }\n                    active\n                }\n                account {\n                    id\n                }\n            }\n        }\n    }\n"])));
export var CREATE_ACCOUNT_MUTATION = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation CreateAccount($input: CreateAccountInput!) {\n        createAccount(input: $input) {\n            id\n        }\n    }\n"], ["\n    mutation CreateAccount($input: CreateAccountInput!) {\n        createAccount(input: $input) {\n            id\n        }\n    }\n"])));
export var UPDATE_ACCOUNT_MUTATION = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation UpdateAccount($id: ID!, $input: UpdateAccountInput!) {\n        updateAccount(id: $id, input: $input) {\n            id\n        }\n    }\n"], ["\n    mutation UpdateAccount($id: ID!, $input: UpdateAccountInput!) {\n        updateAccount(id: $id, input: $input) {\n            id\n        }\n    }\n"])));
export var DELETE_ACCOUNT_MUTATION = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation DeleteAccount($id: ID!) {\n        deleteAccount(id: $id)\n    }\n"], ["\n    mutation DeleteAccount($id: ID!) {\n        deleteAccount(id: $id)\n    }\n"])));
export var REMOVE_LICENSE_MUTATION = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    mutation RemoveLicense($id: ID!) {\n        removeLicenseFromAccount(id: $id)\n    }\n"], ["\n    mutation RemoveLicense($id: ID!) {\n        removeLicenseFromAccount(id: $id)\n    }\n"])));
export var COUNT_ACCOUNTS_QUERY = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    query CountAccounts {\n        countAccounts\n    }\n"], ["\n    query CountAccounts {\n        countAccounts\n    }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
