var _a;
export var PaymentStatusTypes;
(function (PaymentStatusTypes) {
    PaymentStatusTypes["TRIAL"] = "trial";
    PaymentStatusTypes["POST_TRIAL"] = "post-trial";
    PaymentStatusTypes["PAID"] = "paid";
    PaymentStatusTypes["FAILURE"] = "failure";
    PaymentStatusTypes["GRACE"] = "grace";
    PaymentStatusTypes["TRIAL_GRACE"] = "trial-grace";
    PaymentStatusTypes["ONLY_SERVICE_TYPE_CHANGE"] = "server-change";
    PaymentStatusTypes["PENDING"] = "pending";
    PaymentStatusTypes["DOWNGRADE"] = "downgrade";
})(PaymentStatusTypes || (PaymentStatusTypes = {}));
export var ServiceTypes;
(function (ServiceTypes) {
    ServiceTypes["ACCENT"] = "accent";
    ServiceTypes["DENOISER"] = "denoiser";
})(ServiceTypes || (ServiceTypes = {}));
export var ServiceTypesLabels = (_a = {},
    _a[ServiceTypes.ACCENT] = 'Accent Softening',
    _a[ServiceTypes.DENOISER] = 'Noise Cancellation',
    _a);
export var EditLicenseTypes;
(function (EditLicenseTypes) {
    EditLicenseTypes["EDIT_TRIAL_LICENSE"] = "edit_trial_license";
    EditLicenseTypes["EDIT_POST_TRIAL_LICENSE"] = "edit_post_trial_license";
})(EditLicenseTypes || (EditLicenseTypes = {}));
