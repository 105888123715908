import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Link } from 'react-router-dom';
import cls from '../Sidebar.module.css';
export var Section = memo(function (_a) {
    var item = _a.item;
    var renderSection = (_jsxs("div", { children: [item.icon, _jsx("p", { children: item.label })] }));
    return (_jsx("section", { className: cls.parent, children: (item === null || item === void 0 ? void 0 : item.href) ? (_jsx(Link, { className: cls.link, children: renderSection, to: item.href })) : (renderSection) }));
});
Section.displayName = 'Section';
